const handleResponse = async (response) => {
  // Check if the response is not OK (status outside 200-299)
  if (!response.ok) {
    try {
      const error = await response.json(); // Try to get the error message from the response body
      throw new Error(error.message || `HTTP error! status: ${response.status}`);
    } catch (err) {
      // If parsing fails, throw a generic error
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  }

  // Try to parse the JSON response
  try {
    const result = await response.json();
    // Check if the result indicates failure
    if (!result.success) {
      throw new Error(result.message || 'An error occurred');
    }
    return result; // Return the successful result
  } catch (parseError) {
    // Handle JSON parsing errors
    throw new Error('Failed to parse response: ' + parseError.message);
  }
};

export default handleResponse;
