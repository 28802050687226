import React, { useState, useEffect } from 'react';
import { Card, Avatar, Rate, Input, Button, List, Tag, Tooltip, Typography, Tabs, Badge, Space } from 'antd';
import { UserOutlined, EyeOutlined, LinkOutlined, MessageOutlined, ShareAltOutlined } from '@ant-design/icons';
import confetti from 'canvas-confetti';

const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const ProductShowcase = ({ selectedSquare, onViewIncrement, detailModalVisible }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [views, setViews] = useState(selectedSquare.views || 0);
  const [shares, setShares] = useState(selectedSquare.shares || 0);

  console.log('selectedSquare: ', selectedSquare);

  useEffect(() => {
    if (detailModalVisible && selectedSquare.id) {
      setViews(selectedSquare.views || 0);
      if (typeof onViewIncrement === 'function') {
        onViewIncrement(selectedSquare.id);
      }
      launchConfetti();
    }
  }, [detailModalVisible, selectedSquare.id]);

  const handleComment = () => {
    if (comment.trim()) {
      setComments([...comments, comment]);
      setComment('');
    }
  };

  const handleOpenWebsite = () => {
    if (selectedSquare && selectedSquare.id) {
      console.log('Opening website for square:', selectedSquare.id);
      const encodedId = btoa(selectedSquare.id.toString())
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
      const redirectUrl = `/product/${encodedId}`;
      
      // Open in a new window/tab
      window.open(redirectUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const launchConfetti = () => {
    const duration = 3 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 1000 }; // Increased zIndex

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function() {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      confetti(Object.assign({}, defaults, { 
        particleCount, 
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } 
      }));
      confetti(Object.assign({}, defaults, { 
        particleCount, 
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } 
      }));
    }, 250);
  };


  // const handleOpenWebsite = (e) => {
  //   e.preventDefault();
  //   window.open(selectedSquare.website? `${selectedSquare.website}?ref=launchpartyhq` : `https://launchpartyhq.com/product/${selectedSquare.website}`, '_blank');
  // };

  const handleShare = () => {
    if (selectedSquare && selectedSquare.id) {
      // Encode the ID
      const encodedId = btoa(selectedSquare.id.toString())
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
  
      const shareableUrl = `https://launchpartyhq.com/product/${encodedId}`;
      const shareableMessage = `Check out ${selectedSquare.title} ${selectedSquare.tagline} on LaunchPartyHQ!`;
      const twitterIntent = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareableMessage)}&url=${encodeURIComponent(shareableUrl)}`;
      window.open(twitterIntent, '_blank', 'noopener,noreferrer');
      setShares(prevShares => prevShares + 1);
      // Optionally, you can also log the share event to your backend
      // logShareEvent(selectedSquare.id);
    }
  };

  const daysUntilExpiration = Math.ceil((new Date(selectedSquare.expiresAt) - new Date()) / (1000 * 60 * 60 * 24));

  const tabItems = [
    {
      key: '1',
      label: 'About',
      children: <Paragraph>
                {selectedSquare.about || selectedSquare.tagline ? (
                  <span dangerouslySetInnerHTML={{ __html: selectedSquare.about || selectedSquare.tagline }} />
                ) : (
                  ""
                )}
              </Paragraph>    
    },
    {
      key: '2',
      label: 'Unique Selling Points',
      children: (
        <List
          dataSource={selectedSquare.usp ? [selectedSquare.usp] : ["No USPs specified."]}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      )
    },
    {
      key: '3',
      label: 'Reviews',
      children: (
        <>
          <Input.TextArea
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add a comment..."
          />
          <Button
            type="primary"
            icon={<MessageOutlined />}
            onClick={handleComment}
            style={{ marginTop: 8, marginBottom: 16 }}
          >
            Comment
          </Button>
          <List
            dataSource={comments}
            renderItem={(item) => <List.Item>{item}</List.Item>}
            locale={{ emptyText: "No comments yet. Be the first to comment!" }}
          />
        </>
      )
    }
  ];

  return (
    <Card
      cover={
        <img
          data-id={selectedSquare.id}
          data-block_id={selectedSquare.block_id}
          alt={selectedSquare.title || `Square ${selectedSquare.block_id}`}
          src={selectedSquare.image ? `assets/images/${selectedSquare.image}` : 'assets/images/squares/default_square.png'}
          style={{ height: 200, objectFit: 'cover' }}
          onError={(e) => {
            // console.error(`Failed to load image for square ${square.block_id}:`, e);
            e.target.src = 'assets/images/squares/default_square.png';
          }}
        />
      }
      actions={[
        <Tooltip title="Visit Website">
          <Button type="link" icon={<LinkOutlined />} onClick={handleOpenWebsite}>
            Visit
          </Button>
        </Tooltip>,
        <Tooltip title="Views">
          <span>
            <EyeOutlined /> {views}
          </span>
        </Tooltip>,
        <Tooltip title="Share on X.com">
          <Button type="link" icon={<ShareAltOutlined />} onClick={handleShare}>
            Share ({shares})
          </Button>
        </Tooltip>,
      ]}
    >
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <Space align="center">
          <Badge count={selectedSquare.block_id} color="blue" overflowCount={9999}>
          <Avatar 
              src={selectedSquare.image ? `assets/images/${selectedSquare.image}` : '/assets/images/squares/default_square.png'}
              size={64} 
              shape="circle"
              style={{ objectFit: 'cover' }}
              onError={(e) => {
                // console.error(`Failed to load avatar image for square ${selectedSquare.block_id}:`, e);
                e.target.src = '/assets/images/squares/default_square.png';
              }}
            />
          </Badge>
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ margin: 0 }}>{selectedSquare.title}</Title>
            <Text type="secondary"><a href={selectedSquare.website} target="_blank" rel="dofollow">{selectedSquare.tagline}</a></Text>
          </Space>
        </Space>
        <Space>
          <Badge count={`${daysUntilExpiration}d`} color={daysUntilExpiration > 30 ? 'green' : 'orange'} />
          <Text type="secondary">Expires in {daysUntilExpiration} days</Text>
        </Space>
        <Rate allowHalf value={rating} onChange={setRating} />
      </Space>
      
      <Tabs items={tabItems} style={{ marginTop: 16 }} />
    </Card>
  );
};

export default React.memo(ProductShowcase);