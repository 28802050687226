import { useState, useCallback, useEffect } from 'react';

const API_URL = '/api/squares'; // Adjusted to match your CodeIgniter API endpoint
import handleResponse from '../utils/apiHelpers';

const squareApi = {
  // Create a new square
  create: async (newSquare) => {
    console.log('squareApi.create - Creating new square:', newSquare);
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newSquare),
    });
    const result = await handleResponse(response);
    console.log('squareApi.create - Created square:', result.square);
    return result.square;
  },

  // Update an existing square
  update: async (squareId, updatedData) => {
    console.log(`squareApi.update - Updating square ${squareId}`, updatedData);
    const response = await fetch(`${API_URL}/${squareId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedData),
    });
    const result = await handleResponse(response);
    console.log('squareApi.update - Updated square:', result.square);
    return result.square;
  },

  // Delete a square
  delete: async (squareId) => {
    console.log(`squareApi.delete - Deleting square ${squareId}`);
    const response = await fetch(`${API_URL}/${squareId}`, {
      method: 'DELETE',
    });
    await handleResponse(response);
    console.log(`squareApi.delete - Deleted square ${squareId}`);
    return true;
  },

  // Get a specific square by ID
  get: async (squareId) => {
    console.log(`squareApi.get - Fetching square ${squareId}`);
    const response = await fetch(`${API_URL}/${squareId}`);
    const result = await handleResponse(response);
    console.log('squareApi.get - Fetched square:', result.square);
    return result.square;
  },

  // List all squares
  list: async () => {
    console.log('squareApi.list - Fetching all squares');
    const response = await fetch(API_URL);
    const result = await handleResponse(response);
    console.log('squareApi.list - Fetched squares:', result.squares);
    return result.squares;
  },

  rent: async (id, formData) => {
    // Get the CSRF token from the meta tag
    const csrfToken = document.querySelector('meta[name="X-CSRF-TOKEN"]').getAttribute('content');

    // Append the CSRF token to the formData
    formData.append('csrf_token', csrfToken);

    console.log('Form Data:', formData);
  
    const response = await fetch('/api/squares/rent', {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.messages ? Object.values(error.messages).join(', ') : 'Failed to rent square');
    }
    return response.json();
  },
  
  checkAvailability: async (id) => {
    const response = await fetch(`/api/squares/check-availability/${id}`);
    if (!response.ok) {
      throw new Error('Failed to check square availability');
    }
    return response.json();
  },

  // Add the new incrementViews method
  incrementViews: async (id) => {
    const csrfToken = document.querySelector('meta[name="X-CSRF-TOKEN"]').getAttribute('content');
  
    console.log(`squareApi.incrementViews - Incrementing views for square ${id}`);
    try {
      const response = await fetch(`${API_URL}/increment-views/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        },
      });
      const result = await handleResponse(response);
      console.log(`squareApi.incrementViews - Incremented views for square ${id}`, result);
      
      if (result.success) {
        return {
          success: true,
          views: result.views,
          message: result.message
        };
      } else {
        throw new Error(result.message || 'Failed to increment views');
      }
    } catch (error) {
      console.error(`squareApi.incrementViews - Error incrementing views for square ${id}:`, error);
      return {
        success: false,
        message: error.message || 'An error occurred while incrementing views'
      };
    }
  },

  logOutgoingClick: async (squareId) => {
    console.log(`squareApi.logOutgoingClick - Logging click for square ${squareId}`);
    const response = await fetch(`${API_URL}/log-click/${squareId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any necessary headers, like CSRF token if required
      },
    });
    const result = await handleResponse(response);
    console.log(`squareApi.logOutgoingClick - Logged click for square ${squareId}`, result);
    return result;
  },

  // Refetch all squares
  refetch: async () => {
    console.log('squareApi.refetch - Refetching all squares');
    return squareApi.list();
  },

  // Search squares by title
  search: (squares, query) => {
    console.log('squareApi.search - Searching squares with query:', query);
    const filteredSquares = squares.filter(square =>
      square.title.toLowerCase().includes(query.toLowerCase())
    );
    console.log('squareApi.search - Filtered squares:', filteredSquares);
    return filteredSquares;
  },
};

export default squareApi;
