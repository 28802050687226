import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Input, Button, message, Upload, Typography, Form, Spin, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import squareApi from '../api/squareApi';
import ProductShowcase from './ProductShowcase';

const { Text } = Typography;
const SQUARE_PRICE = 10;
const SQUARE_SIZE = 39;
const TOTAL_SQUARES = 1024;
const SQUARES_PER_BLOCK = 32;

function Homepage() {
  const [messageApi, contextHolder] = message.useMessage();
  const [squares, setSquares] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gridWidth, setGridWidth] = useState(window.innerWidth);
  const [selectedSquare, setSelectedSquare] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [rentModalVisible, setRentModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [formData, setFormData] = useState({ title: '', link: '', image: null });
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [squareViews, setSquareViews] = useState({});

  useEffect(() => {
    fetchSquares();
    const handleResize = () => setGridWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchSquares = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await squareApi.list();
      console.log('Raw API response:', data);
      
      // Create a mapping of block_id to square data
      const squareMap = data.reduce((acc, square) => {
        acc[square.block_id] = square;
        return acc;
      }, {});
  
      console.log('Square map:', squareMap);
  
      const updatedSquares = Array(TOTAL_SQUARES).fill(null).map((_, index) => {
        const blockId = Math.floor(index / SQUARES_PER_BLOCK) + 1;
        const squareId = (index + 1).toString(); // Convert to string to match API data
        const apiSquare = squareMap[squareId];
        
        const square = {
          grid_id: index,
          id: null,
          block_id: squareId,
          owner: null,
          image: null,
          website: null,
          domain_lander: null,
          title: '',
          views: 0,
          outbound_clicks: 0,
          shares: 0,
          avg_rating: 0,
          score: 0,
          about: '',
          usp: '',
          tagline: '',
          rentedAt: null,
          expiresAt: null,
          block: `Block ${blockId}`,
        };
  
        if (apiSquare) {
          Object.assign(square, apiSquare);
          square.id = apiSquare.id;
          // square.id = index; // Ensure the id remains as the index
        }
  
        // console.log(`Processing square ${index}, squareId: ${squareId}, square:`, square);
        return square;
      });
  
      console.log('Updated squares:', updatedSquares);
      setSquares(updatedSquares);
    } catch (error) {
      console.error('Error loading squares:', error);
      setError('Failed to load squares.');
    } finally {
      setLoading(false);
    }
  };

  const rentSquare = useCallback(async (id, image, title, website) => {
    try {
      const updatedSquare = await squareApi.update(id, { image, title, website });
      setSquares((prevSquares) => 
        prevSquares.map((square) => 
          square.id === updatedSquare.id ? { ...square, ...updatedSquare } : square
        )
      );
    } catch (error) {
      console.error('Error renting square:', error);
      setError('Failed to rent the square.');
    } finally {
      setSelectedSquare(null);
      setModalVisible(false);
      setFormData({ image: '', owner: '', website: '' });
    }
  }, []);

  const handleSquareClick = useCallback(async (square) => {
    console.log('clicked square: ', square);
    setSelectedSquare(square);
    if (square.title) {
      setDetailModalVisible(true);
      // Open the renter's website in a new tab
      // if (square.website) {
      //   window.open(square.website, '_blank', 'noopener,noreferrer');
      // }
    } else {
      try {
        
        console.log('checking availability for square block_id: ', square.block_id);

        const response = await squareApi.checkAvailability(square.block_id);
        if (response.available) {
          setRentModalVisible(true);
        } else {
          messageApi.error('This square is no longer available. Please choose another one.');
          fetchSquares(); // Refresh the squares data
        }
      } catch (error) {
        console.error('Error checking square availability:', error);
        messageApi.error('Failed to check square availability. Please try again.');
      }
    }
  }, [messageApi]);

  const handleModalClose = () => {
    setRentModalVisible(false);
    setDetailModalVisible(false);
    setSelectedSquare(null);
    form.resetFields();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (info) => {
    if (info.file.status === 'done') {
      setFormData(prev => ({ ...prev, image: info.file.originFileObj }));
      messageApi.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      messageApi.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleRentSubmit = async (values) => {
    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('tagline', values.tagline);
      formData.append('about', values.about);
      formData.append('usp', values.usp);
      formData.append('link', values.link);
      formData.append('image', values.image[0].originFileObj);
      formData.append('block_id', selectedSquare.block_id);
  
      const response = await squareApi.rent(selectedSquare.block_id, formData);
      messageApi.success('Square reserved successfully! Redirecting to payment...');
      
      // Simulate redirect to payment page
      setTimeout(() => {
        window.location.href = `/payment?square=${selectedSquare.block_id}&amount=24`;
      }, 2000);
    } catch (error) {
      console.error('Error renting square:', error);
      if (error.message.includes('CSRF')) {
        messageApi.error('Session expired. Please refresh the page and try again.');
      } else {
        messageApi.error('Failed to rent square. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleOpenWebsite = () => {
    if (selectedSquare && selectedSquare.website) {
      const encodedUrl = btoa(`${selectedSquare.website}?squareId=${selectedSquare.block_id}`);
      window.location.href = `/product/${encodedUrl}`;
    }
  };

  const onViewIncrement = async (id) => {
    try {
      const response = await squareApi.incrementViews(id);
      if (response.success) {
        setSquareViews(prev => ({
          ...prev,
          [id]: response.views // Assuming the API returns the new view count in a 'views' field
        }));
        console.log('View count incremented successfully - response views ', response.views);
      } else {
        console.error('Failed to increment view count:', response.message);
      }
    } catch (error) {
      console.error('Error incrementing view count:', error);
    }
  };

   const squaresPerRow = Math.floor(gridWidth / SQUARE_SIZE);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


  // const squaresPerRow = Math.floor(gridWidth / SQUARE_SIZE);

  console.log('squares in Homepage: ', squares);

  return (
    <>
    {contextHolder}
      <div 
        className="grid gap-0 justify-center"
        style={{
          gridTemplateColumns: `repeat(${squaresPerRow}, ${SQUARE_SIZE}px)`,
          gridAutoRows: `${SQUARE_SIZE}px`,
        }}
      >
        {squares.map((square) => (
          <Tooltip
            key={`square-${square.grid_id}-${square.block_id}`}
            title={
              square.title
                ? `#${square.block_id} ${square.title} | Clicks: ${square.views || 0}`
                : `#${square.block_id} Your product can be here!`
            }
            placement="top"
          >
            <div
              onClick={() => handleSquareClick(square)}
              className={`cursor-pointer border border-gray-300 flex items-center justify-center relative ${
                square.title ? 'bg-white' : 'bg-gray-100'
              }`}
            >
              {square.title ? (
                <img 
                  data-id={square.id}
                  data-grid_id={square.grid_id}
                  data-block_id={square.block_id}
                  src={square.image ? `assets/images/${square.image}` : 'assets/images/squares/default_square.png'} 
                  alt={square.title || `Square ${square.block_id}`}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    // console.error(`Failed to load image for square ${square.block_id}:`, e);
                    e.target.src = 'assets/images/squares/default_square.png';
                  }}
                />
              ) : (
                <span className="text-xs text-gray-500" data-grid_id={square.grid_id} data-block_id={square.block_id}>
                  
                </span>
              )}
            </div>
          </Tooltip>
        ))}
      </div>

      {/* Rent Modal */}
      <Modal
        open={rentModalVisible}
        onCancel={handleModalClose}
        title={`Launch Party #${selectedSquare ? selectedSquare.block_id : ''}`}
        footer={null}
      >
        <p className="mb-4 text-gray-600">Launch your startup party for $24/year (limited time offer).</p>
        <Form form={form} onFinish={handleRentSubmit} layout="vertical">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a catchy caption' }]}
          >
            <Input placeholder="Your launch title" />
          </Form.Item>
          <Form.Item
            name="tagline"
            label="Tagline"
            rules={[{ required: true, message: 'Please enter a tagline' }]}
          >
            <Input placeholder="Your catchy tagline" />
          </Form.Item>
          <Form.Item
            name="about"
            label="About"
            rules={[{ required: true, message: 'Please provide a brief description' }]}
          >
            <Input.TextArea rows={3} placeholder="Brief description of your startup" />
          </Form.Item>
          <Form.Item
            name="usp"
            label="USP (Unique Selling Proposition)"
            rules={[{ required: true, message: 'Please enter your USPs' }]}
          >
            <Input.TextArea 
              rows={5} 
              placeholder="Enter 5-6 unique selling points, separated by commas"
            />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link"
            rules={[
              { required: true, message: 'Please enter a link' },
              { type: 'url', message: 'Please enter a valid URL' }
            ]}
          >
            <Input placeholder="https://cycledai.com" />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Please upload an image' }]}
          >
            <Upload 
              maxCount={1}
              beforeUpload={() => false} // Prevent auto upload
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full" loading={submitting}>
              {submitting ? 'Processing...' : 'Launch Party'}
            </Button>
          </Form.Item>
        </Form>
        <p className="mt-4 text-xs text-gray-500">
          By proceeding, you agree to our <a href="#" className="text-blue-500">Terms of Service</a> and <a href="#" className="text-blue-500">Privacy Policy</a>.
        </p>
      </Modal>

      {/* Detail Modal */}
      <Modal
        open={detailModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={400}
        className="rounded-lg overflow-hidden"
        styles={{
          body: { padding: 0 },
          content: { borderRadius: '8px', overflow: 'hidden' },
          mask: { backgroundColor: 'rgba(0, 0, 0, 0.45)' },
          wrapper: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            paddingTop: '5vh', // This will set the top margin to 5% of the viewport height
          },
        }}
      >
        {selectedSquare && (
          <>
            <ProductShowcase 
              selectedSquare={{
                ...selectedSquare,
                views: squareViews[selectedSquare.id] || selectedSquare.views || 0
              }}
              onViewIncrement={onViewIncrement}
              detailModalVisible={detailModalVisible}
            />
          </>
        )}
      </Modal>
    </>
  );
}

export default Homepage;